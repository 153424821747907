import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Point from "../components/point";
import Seo from "../components/seo";

const content = {
  hero: {
    title: "Drive results.",
    text: "The cascading benefits of multimodal technology.",
    lede:
      "Voxware improves every aspect of your supply chain. Select an area of the warehouse to see how your business can gain efficiences and provide better customer experiences with Voxware.",
  },
};

const Frame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background: url("/images/main-background.jpg");
  background-position: center;
  background-size: cover;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
`;

const Hero = styled.section`
  margin-top: 10rem;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    z-index: 100;
    position: absolute;
    left: 0;
    top: 100px;
    right: 0;
    margin: 0;
    text-align: left;
  }
`;

const Container = styled.div`
  padding: 0 3rem;
  max-width: 150rem;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 6rem;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 4.8rem;
  font-weight: ${({ theme }) => theme.fontWeights.black};
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 6.8rem;
  }
`;

const Text = styled.p`
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

const Lede = styled.p`
  max-width: 46.6rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.whiteOpaque};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 0;
  }
`;

const Arrow = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
    margin-top: 5.6rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWorkflowsJson {
        edges {
          node {
            title
            slug
            order
            feature {
              text
              alignment
              artwork {
                mobile
                tablet
              }
            }
            position {
              x
              y
            }
          }
        }
      }
    }
  `);
  const { edges } = data.allWorkflowsJson;
  const items = edges.sort((a, b) => a.node.order - b.node.order);
  return (
    <Layout footerPosition={"absolute"} current="">
      <Seo
        title={"Voxware - The cascading benefits of multimodal technology"}
        description={
          "Voxware improves every aspect of your supply chain. Select an area of the warehouse to see how your business can gain efficiencies and provide better customer experiences with Voxware."
        }
        openGraphImage={"/images/voxware-home-og.png"}
      />
      <Hero>
        <Container>
          <Title>{content.hero.title}</Title>
          <Text>{content.hero.text}</Text>
          <Lede>{content.hero.lede}</Lede>
          <Arrow>
            <svg
              width="28"
              height="42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.98 26.569c-.703-.717-1.87-.717-2.588-.014l-8.564 8.536V2.564c0-.998-.816-1.814-1.828-1.814a1.821 1.821 0 00-1.828 1.814v32.527l-8.564-8.55a1.85 1.85 0 00-2.588.014 1.82 1.82 0 00.014 2.573l11.672 11.588c.169.154.352.28.577.38a1.833 1.833 0 001.983-.38l11.671-11.588a1.791 1.791 0 00.043-2.56z"
                fill="#61616A"
              />
            </svg>
          </Arrow>
        </Container>
      </Hero>
      <Frame />
      {items.map((item, i) => (
        <Point
          title={item.node.title}
          position={item.node.position}
          slug={item.node.slug}
          key={i}
          feature={item.node.feature}
          artwork={item.node.feature.artwork}
          alignment={item.node.feature.alignment}
        />
      ))}
    </Layout>
  );
};

export default IndexPage;
