import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { Link } from "gatsby";

const pulseRing = keyframes`
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const pulseDot = keyframes`
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
`;

const Title = styled.p`
  transition: ${({ theme }) => theme.animations.duration} all ease;
  padding-bottom: 1rem;
  border-bottom: 3px solid transparent;
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: 2.8rem;
`;

const Container = styled((props) => <Link {...props} />)`
  position: relative;
  display: block;
  text-decoration: none;
  &:hover ${Title}, &:focus ${Title} {
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
      border-bottom: 3px solid ${({ theme }) => theme.colors.brand};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    height: 60rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    z-index: 500;
    position: absolute;
    top: ${(props) => props.position.y};
    left: ${(props) => props.position.x};
    border: none;
    height: auto;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  justify-content: ${(props) => props.alignment};
  padding: 0 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  max-width: 45rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: 15rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    max-width: auto;
    padding: 0;
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 15px;
  left: -25px;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: block;
  }
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: ${({ theme }) => theme.colors.brand};
    animation: ${pulseRing} 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.brand};
    border-radius: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: ${pulseDot} 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

const Text = styled.p`
  display: block;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 12.8rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const LinkStub = styled.p`
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const LinkStubText = styled.span`
  margin-right: 1.5rem;
  border-bottom: 4px solid ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brand};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

const Artwork = styled.div`
  z-index: -1;
  display: block;
  height: 45rem;
  background-image: url("/images/${(props) => props.mobile}");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60rem;
    background-image: url("/images/${(props) => props.tablet}");
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

const Point = ({ title, position, slug, feature, artwork, alignment }) => {
  const { text } = feature;
  return (
    <Container position={position} to={`/${slug}/`}>
      <Wrapper>
        <Artwork mobile={artwork.mobile} tablet={artwork.tablet} />
        <Inner alignment={alignment}>
          <InnerWrapper>
            <Title>{title}</Title>
            <Text>{text}</Text>
            <Circle />
            <LinkStub>
              <LinkStubText>Learn about Voxware Analytics</LinkStubText>
              <svg
                width="16"
                height="11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.04.45a.715.715 0 00-.005 1.007l3.325 3.33H.705A.708.708 0 000 5.498c0 .394.317.711.705.711h12.65L10.03 9.54a.72.72 0 00.005 1.007.708.708 0 001-.005L15.543 6a.798.798 0 00.148-.224.713.713 0 00-.148-.771L11.036.467a.697.697 0 00-.995-.016z"
                  fill="#FFDF00"
                />
              </svg>
            </LinkStub>
          </InnerWrapper>
        </Inner>
      </Wrapper>
    </Container>
  );
};

Point.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  position: PropTypes.shape({
    x: PropTypes.string.isRequired,
    y: PropTypes.string.isRequired,
  }).isRequired,
};

export default Point;
